import React from "react";
import "./App.css";
import Calculator from "./Calculator"; // Import the calculator component

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Calculator /> {/* Add the calculator component here */}
      </header>
    </div>
  );
}

export default App;
