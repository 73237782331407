import React, { useState, useEffect } from "react";
import { evaluate } from "mathjs";
import "./Calculator.css";

function Calculator() {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");

  const handleClick = (value) => {
    setInput(input + value);
  };

  const calculate = () => {
    try {
      setResult(evaluate(input)); // Don't use eval() in production for security reasons
    } catch (error) {
      console.log(error)
      setResult("Error");
    }
  };

  const clear = () => {
    setInput("");
    setResult("");
  };

  const handleKeyDown = (event) => {
    const key = event.key;

    if (
      (key >= "0" && key <= "9") ||
      key == "+" ||
      key === "-" ||
      key === "*" ||
      key === "/"
    ) {
      setInput((input) => input + key);
    } else if (key === "Enter"){
      calculate();
    } else if (key === "Backspace"){
      setInput((input) => input.slice(0, -1));
    } else if (key === "Escape" || key.toLowerCase() === "c"){
      clear();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="calculator">
      <div className="display">
        <input type="text" value={input} readOnly />
        <div className="result">{result}</div>
      </div>
      <div className="buttons">
        <button onClick={() => handleClick("1")}>1</button>
        <button onClick={() => handleClick("2")}>2</button>
        <button onClick={() => handleClick("3")}>3</button>
        <button onClick={() => handleClick("+")}>+</button>
        <button onClick={() => handleClick("4")}>4</button>
        <button onClick={() => handleClick("5")}>5</button>
        <button onClick={() => handleClick("6")}>6</button>
        <button onClick={() => handleClick("-")}>-</button>
        <button onClick={() => handleClick("7")}>7</button>
        <button onClick={() => handleClick("8")}>8</button>
        <button onClick={() => handleClick("9")}>9</button>
        <button onClick={() => handleClick("*")}>*</button>
        <button onClick={() => handleClick("0")}>0</button>
        <button onClick={() => handleClick("/")}>/</button>
        <button onClick={clear}>C</button>
        <button onClick={calculate}>=</button>
      </div>
    </div>
  );
}

export default Calculator;
